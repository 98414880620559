import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProgramaContactosService } from 'src/app/core/services/api/programa-contactos.service';
import { ProgramService } from 'src/app/core/services/api/program.service';
import { CargosService } from 'src/app/core/services/api/cargos.service';
import { Option } from 'app/core/contracts/models/option';
import { PeopleService } from 'src/app/core/services/api/people.service';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewPersonComponent } from '../../new-person/new-person.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { ProgramLegacy } from '../../../core/contracts/models/program.model';
import { ProgramaContactos } from 'src/app/core/contracts/models/contact-program.interface';
import { Contact } from 'src/app/core/contracts/models/contacto.model';

@Component({
    selector: 'app-asignar-contactos-form',
    templateUrl: './asignar-contactos-form.component.html',
    styleUrls: ['./asignar-contactos-form.component.scss']
})
export class AsignarContactosFormComponent implements OnInit, OnDestroy {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        contacto: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        programa: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        influencia: new FormControl<number | null>(null, [Validators.required]),
        cargo: new FormControl<string | null>(null, [Validators.required]),
        plataforma: new FormControl<any>({ value: null, disabled: true }, [Validators.required]),
        observacion: new FormControl<string | null>(null),
        filter: new FormControl<string | null>(null),
        filterC: new FormControl<string | null>(null),
        filterP: new FormControl<string | null>(null),
    });

    dataContacto: ProgramaContactos = {
        programa_id: null,
        idContacto: null,
        tipoInfluencia: null,
        idsCargo: null,
        idsMedioPlataforma: null,
        observacion: null
    }

    title = 'Agregar contacto';
    formSubmitAttempt = false;

    subscriptionForm: Subscription;

    cargos: Option[] = [];
    programas: ProgramLegacy[] = [];
    plataformas: any[] = [];
    selectedPlataforma: any[] = [];
    selectedCargo: any[] = [];
    contacts: Contact[] = [];

    selectPlat$: Observable<any>;

    noPlatform = false;

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarContactosFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, id: number },
        private programaContactosService: ProgramaContactosService,
        private programService: ProgramService,
        private peopleService: PeopleService,
        private cargosService: CargosService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.cargosService.getAll().subscribe((response: any) => {
            const { cargos } = response;
            this.cargos = cargos;
        });

        if (!this.data.row) {
            this.peopleService.getContactosSelect().subscribe((response: any) => {
                const { contactos } = response;
                this.contacts = contactos.map(ct => {
                    ct.completeName = `${ct.nombres} ${ct.apellidos}`;
                    return ct;
                });
                this.myForm.controls.contacto.enable({ emitEvent: false });
            });
        }

        this.programService.getAll(this.data.id).subscribe(result => {
            this.programas = result;
            if (!this.data.row) {
                this.myForm.controls.programa.enable({ emitEvent: false });
            }
        });

        this.selectPlat$ = this.myForm.controls.plataforma.valueChanges.pipe(
            map(plats => {
                return plats ? this.plataformas.filter(pl => plats.includes(pl.idMedioPlataforma)) : []
            })
        )

        this.subscriptionForm = this.myForm.controls.programa.valueChanges.subscribe(id => {
            this.myForm.controls.plataforma.reset();
            if (id) {
                this.getAllProgramaPlataformas(id);
            }
        });

        if (this.data.row) {
            this.title = 'Editar contacto';
            this.programaContactosService.getId(this.data.row.id).subscribe(response => {
                const programaContacto = response['programaContacto'];

                const cargos = programaContacto.idsCargo ? programaContacto.idsCargo.split(',').map(n => Number(n)) : []
                this.contacts = [programaContacto.contacto];
                this.myForm.controls.contacto.setValue(programaContacto.contacto.id);
                this.myForm.controls.programa.setValue(programaContacto.programa_id);
                this.myForm.controls.influencia.setValue(programaContacto.tipoInfluencia);
                this.myForm.controls.cargo.setValue(cargos);
                this.myForm.controls.observacion.setValue(programaContacto.observacion);
            })
        }
    }

    ngOnDestroy() {
        this.subscriptionForm.unsubscribe();
    }

    getAllProgramaPlataformas(id) {
        if (this.noPlatform) {
            this.noPlatform = false;
        }

        this.programService.getAllProgramaPlataformas(id).subscribe((response: any) => {
            const { programaPlataformas } = response;
            this.plataformas = programaPlataformas.map(pr => {
                return {
                    ...pr,
                    plat: pr.medio_plataforma.plataforma_clasificacion.plataforma.descripcion,
                    descrip: pr.medio_plataforma.plataforma_clasificacion.descripcion,
                    val: pr.medio_plataforma.valor
                }
            });

            if (this.plataformas.length) {
                if (this.myForm.controls.plataforma.disabled) {
                    this.myForm.controls.plataforma.enable({ emitEvent: false });
                }
            } else {
                this.noPlatform = true;
            }

            setTimeout(() => {
                if (this.data.row) {
                    let plats = this.data.row.idsMedioPlataforma.split(',').map(n => Number(n))
                        .filter(p => this.plataformas.find(pl => pl.idMedioPlataforma == p))
                    this.myForm.get('plataforma').setValue(plats);
                }
            });
        });
    }

    remove(id): void {
        let preSelects = [...this.myForm.get('plataforma').value];

        const index = preSelects.indexOf(id);
        if (index != -1) {
            const filteredOptions = [...preSelects].filter(op => op != id);
            this.myForm.get('plataforma').patchValue(filteredOptions);
        }
    }

    onSubmit() {
        this.formSubmitAttempt = true;
        this.dataContacto = {
            idContacto: this.myForm.controls.contacto.value,
            idsCargo: this.myForm.controls.cargo.value,
            idsMedioPlataforma: this.myForm.controls.plataforma.value,
            observacion: this.myForm.controls.observacion.value,
            programa_id: this.myForm.controls.programa.value,
            tipoInfluencia: this.myForm.controls.influencia.value
        }

        let observable: Observable<any> | undefined = undefined
        if (this.data.row) {
            this.dataContacto.id = this.data.row.id;
            observable = this.programaContactosService.update(this.dataContacto)
        } else {
            observable = this.programaContactosService.create(this.dataContacto)
        }
        this.loading = true;
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false;
        })
    }

    error(error) {
        if (error.error.errors.idContacto) {
            this.swal.showToast(error.error.errors.idContacto[0], 'error');
            return;
        }
        if (error.error.errors.idsMedioPlataforma) {
            this.swal.showToast(error.error.errors.idsMedioPlataforma[0], 'error');
            return;
        }
        this.swal.showToast(error.error.message, 'error');
    }

    newPerson() {
        if (this.myForm.controls.contacto.value == -1) {
            this.dialog.open(NewPersonComponent, {
                data: { tipo: [1], idCliente: null },
                width: '600px'
            }).afterClosed().subscribe(res => {
                if (res) {
                    res.completeName = res.nombres + ' ' + res.apellidos;
                    this.contacts.push(res);
                    this.myForm.controls.contacto.setValue(res.id);
                } else {
                    this.myForm.controls.contacto.setValue(null);
                }
            });
        }
    }
}