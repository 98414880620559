import { Injectable, inject } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth/auth.service";

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);
    
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
      const isLoggedIn = Boolean(this.auth.user) && Boolean(this.auth.getTokenValue());
      const isApiUrl = request.url.startsWith(environment.app_urls.source);
  
      if (isLoggedIn && isApiUrl) {
        const Authorization = `Bearer ${this.auth.getTokenValue()}`;
        request = request.clone({ setHeaders: {Authorization} });
      }
  
      return next.handle(request);
  }
}
