import {Component, Inject, OnInit} from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Option } from 'app/core/contracts/models/option';
import {CargosService} from 'src/app/core/services/api/cargos.service';
import {MediaService} from 'src/app/core/services/api/media.service';
import {ProgramService} from 'src/app/core/services/api/program.service';
import {ProgramaContactosService} from 'src/app/core/services/api/programa-contactos.service';
import {Subscription, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { Media } from 'src/app/core/contracts/models/media.interface';

@Component({
    selector: 'app-asignar-medio-form',
    templateUrl: './asignar-medio-form.component.html',
    styleUrls: ['./asignar-medio-form.component.scss']
})
export class AsignarMedioFormComponent implements OnInit {
    public loading:boolean = false;

    myForm = this.formBuilder.group({
        medio: new FormControl<number|null>(null,Validators.required),
        programa:new FormControl<number|null>(null,Validators.required),
        influencia: new FormControl<number|null>(null,Validators.required),
        cargo: new FormControl<number|null>(null,Validators.required),
        plataforma: new FormControl<Array<any>>({value: [], disabled: true}, Validators.required),
        observacion: new FormControl<string|null>(null) ,
        filter: new FormControl<string|null>(null),
        filterC: new FormControl<string|null>(null),
        filterP: new FormControl<string|null>(null),
    });
    
    dataContacto: any = {
        programa_id: null,
        idContacto: null,
        tipoInfluencia: null,
        idsTipoCargo: null,
        idsMedioPlataforma: null,
        observacion: null
    }

    title = 'Agregar medio';
    formSubmitAttempt = false;

    cargos: Option[] = [];
    programas: any[] = [];
    plataformas: any[] = [];
    medios: Media[] = [];

    selectPlat$: Observable<any>;

    subscriptionFormMedio: Subscription;
    subscriptionFormPrograma: Subscription;

    noPlatform = false;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarMedioFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, id: number },
        private programaContactosService: ProgramaContactosService,
        private mediaService: MediaService,
        private programService: ProgramService,
        private cargosService: CargosService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {

        this.cargosService.getAll().subscribe((response: any) => {
            const {cargos} = response;
            this.cargos = cargos;
        });

        if (!this.data.row) {
            this.mediaService.getListSelect().subscribe((response: any) => {
                const {medios} = response;
                this.medios = medios;
                this.myForm.get('medio').enable({emitEvent: false});
            })
        }
        this.selectPlat$ = this.myForm.get('plataforma').valueChanges.pipe(
            map(plats => {
                if (plats) {
                    return this.plataformas.filter(pl => plats.includes(pl.idMedioPlataforma));
                } else {
                    return [];
                }
            })
        )

        this.subscriptionFormMedio = this.myForm.get('medio').valueChanges.subscribe(id => {
            this.myForm.get('plataforma').reset();
            this.myForm.get('programa').reset();
            if (id && !this.data.row) {
                this.getListByMedio(id);

            } else {
                if (this.myForm.get('programa').enabled) {
                    this.myForm.get('programa').disable({emitEvent: false});
                }
            }
        });

        this.subscriptionFormPrograma = this.myForm.get('programa').valueChanges.subscribe(id => {
            this.myForm.get('plataforma').reset();
            if (id) {
                this.getAllProgramaPlataformas(id);
            }
        });

        if (this.data.row) {
            this.title = 'Editar medio';
            const cargo = this.data.row.idsCargo ? this.data.row.idsCargo.split(',').map(n => Number(n)) : [];
            this.medios = [this.data.row.programa.medio];
            this.programas = [this.data.row.programa];
            this.myForm.get('medio').setValue(this.data.row.programa.medio.id);
            this.myForm.get('programa').setValue(this.data.row.programa_id);
            this.myForm.get('influencia').setValue(this.data.row.tipoInfluencia);
            this.myForm.get('cargo').setValue(cargo);
            this.myForm.get('observacion').setValue(this.data.row.observacion);
        }
    }

    ngOnDestroy() {
        this.subscriptionFormMedio.unsubscribe();
        this.subscriptionFormPrograma.unsubscribe();
    }

    getListByMedio(id) {

        if (this.noPlatform) {
            this.noPlatform = false;
        }
        if (this.myForm.get('plataforma').enabled) {
            this.myForm.get('plataforma').disable({emitEvent: false});
        }
        this.programService.getListByMedio(id).subscribe((response: any) => {
            const {programas} = response;
            this.programas = programas;
            this.myForm.get('programa').enable({emitEvent: false});
        });
    }

    getAllProgramaPlataformas(id) {
        if (this.noPlatform) {
            this.noPlatform = false;
        }

        this.programService.getAllProgramaPlataformas(id).subscribe((response: any) => {
            const {programaPlataformas} = response;
            this.plataformas = programaPlataformas.map(pr => {
                return {
                    ...pr,
                    plat: pr.medio_plataforma.plataforma_clasificacion.plataforma.descripcion,
                    descrip: pr.medio_plataforma.plataforma_clasificacion.descripcion,
                    val: pr.medio_plataforma.valor
                }
            });

            if (this.plataformas.length) {
                if (this.myForm.get('plataforma').disabled) {
                    this.myForm.get('plataforma').enable({emitEvent: false});
                }
            } else {
                this.noPlatform = true;
            }

            setTimeout(() => {
                if (this.data.row) {
                    const plats = this.data.row.idsMedioPlataforma.split(',').map(n => Number(n))
                        .filter(p => this.plataformas.find(pl => pl.idMedioPlataforma == p));
                    this.myForm.get('plataforma').setValue(plats);
                }
            });
        });
    }

    remove(id): void {
        const preSelects = [...this.myForm.get('plataforma').value];

        const index = preSelects.indexOf(id);
        if (index != -1) {
            const filteredOptions = [...preSelects].filter(op => op != id);
            this.myForm.get('plataforma').patchValue(filteredOptions);
        }
    }


    onSubmit() {
        this.formSubmitAttempt = true;
        this.dataContacto = {
            idContacto: this.data.id,
            idsCargo: this.myForm.controls.cargo.value,
            idsMedioPlataforma: this.myForm.controls.plataforma.value,
            observacion: this.myForm.controls.observacion.value,
            programa_id: this.myForm.controls.programa.value,
            tipoInfluencia: this.myForm.controls.influencia.value
        }
                
        let observable:Observable<any>|undefined = undefined
        if (this.data.row) {
            this.dataContacto.id = this.data.row.id;
            observable=this.programaContactosService.update(this.dataContacto)
        } else {
            observable=this.programaContactosService.create(this.dataContacto)
        }
        this.loading = true
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false
        })
    }


    error(error) {
        if (error.error.errors.idContacto) {
            this.swal.showToast(error.error.errors.idContacto[0], 'error');
            return;
        }
        if (error.error.errors.idsMedioPlataforma) {
            this.swal.showToast(error.error.errors.idsMedioPlataforma[0], 'error');
            return;
        }
        this.swal.showToast(error.error.message, 'error');
    }
}
