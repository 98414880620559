import {Component, Inject, OnInit} from '@angular/core';
import {Validators, UntypedFormBuilder} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PlatformService} from 'src/app/core/services/api/platform.service';
import {ProgramService} from 'src/app/core/services/api/program.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PlatformMedia } from 'src/app/core/contracts/models/medio-plataformas';
import { ProgramPlatform } from 'src/app/core/contracts/models/program-platform';
import { Program } from 'src/app/core/contracts/models/programa.model';

@Component({
    selector: 'app-asignar-programas-link',
    templateUrl: './asignar-programas-link.component.html',
    styleUrls: ['./asignar-programas-link.component.scss']
})
export class AsignarProgramasLinkComponent implements OnInit {

    myForm = this.formBuilder.group({
        platform: ['', Validators.required],
        value: ['']
    });

    id: any;

    dataForm: ProgramPlatform = {
        id: null,
        programa_id: null,
        idMedioPlataforma: null,
        valor: null,
    }

    buttonSubmit = true;
    title = 'Detalles del programa';
    messages = {emptyMessage: 'No hay información para mostrar.'};

    platforms: PlatformMedia[] = [];
    rows: ProgramPlatform[] = [];

    isLoading = true;

    addPermission = this.auth.isPermit('medio.programa-plataforma.add');
    editPermission = this.auth.isPermit('medio.programa-plataforma.edit');
    deletePermission = this.auth.isPermit('medio.programa-plataforma.delete');

    constructor(
        private formBuilder: UntypedFormBuilder,
        private platformService: PlatformService,
        private programService: ProgramService,
        public dialogRef: MatDialogRef<AsignarProgramasLinkComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Program,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.platformService.getListByMedio(this.data.medio_id).subscribe((response: any) => {
            const {medioPlataformas} = response;
            this.platforms = medioPlataformas;
        });
        this.getAll();
    }


    getAll() {
        this.programService.getAllProgramaPlataformas(this.data.id).subscribe((response: any) => {
            const {programaPlataformas} = response;
            this.rows = programaPlataformas;
            this.isLoading = false;
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 200);
        });
    }

    onSubmit() {
        this.buttonSubmit = false;
        if (this.myForm.invalid) {
            this.buttonSubmit = true;
            return;
        }

        this.dataForm.valor = this.myForm.get('value').value;
        this.dataForm.programa_id = this.data.id;
        this.dataForm.idMedioPlataforma = this.myForm.get('platform').value;

        if (this.id) {
            this.dataForm.id = this.id;
            this.programService.updateProgramaPlataformas(this.dataForm).subscribe((response: any) => {
                this.getAll();
                this.myForm.reset();
                Object.keys(this.myForm.controls).forEach(control => {
                    this.myForm.get(control).setErrors(null);
                });
                this.id = null;
                this.myForm.get('platform').enable();
                this.swal.showToast(response.message, 'success');
                this.buttonSubmit = true;
            }, error => {
                if (error.status === 400) {
                    this.error(error);
                }
                this.buttonSubmit = true;
            });

        } else {
            this.programService.createProgramaPlataformas(this.dataForm).subscribe((response: any) => {
                this.getAll();
                this.myForm.reset();
                Object.keys(this.myForm.controls).forEach(control => {
                    this.myForm.get(control).setErrors(null);
                });
                this.swal.showToast(response.message, 'success');
                this.buttonSubmit = true;
            }, error => {
                if (error.status === 400) {
                    this.error(error);
                }
                console.warn(error);
                this.buttonSubmit = true;
            });
        }
    }

    error(error) {
        if (error.error.errors.idMedioPlataforma) {
            this.swal.showToast(error.error.errors.idMedioPlataforma[0], 'error');
        }
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.programService.deleteProgramaPlataformas(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }

    edit(row: ProgramPlatform) {
        this.id = row.id;
        this.myForm.get('value').setValue(Number(row.valor));
        this.myForm.get('platform').setValue(row.idMedioPlataforma);
        this.myForm.get('platform').disable();
    }

    cancelEdit() {
        this.id = null;
        this.myForm.markAllAsTouched();
        this.myForm.reset();
        this.myForm.get('platform').enable();
    }

    cancel() {
        this.dialogRef.close();
    }

    onChangeSelect() {
        const find = this.platforms.find(pl => pl.id == this.myForm.get('platform').value)
        if (find && find.valorizacion_plataforma) {
            this.myForm.get('value').setValue(Number(find.valorizacion_plataforma));
        }
    }
}
